import React, { useEffect } from "react";
import Confetti from "react-confetti";
import CustomModal from "common/CustomModal";
import { useModalGac } from "hooks/useModalGac";
import { ReactComponent as CloseIcon } from "assets/icons/components/search/close.svg";
import "styles/pop-up/index.css";

const MainPopUp = () => {
  const [isOpenModal, openModal, closeModal] = useModalGac(false);
  const isMobile = window.innerWidth < 768;
  useEffect(() => {
    openModal();
  }, []);

  return (
    <>
      <CustomModal isOpen={isOpenModal} closeModal={closeModal}>
        <button className="btn-close-modal" onClick={closeModal}>
          <CloseIcon className="icon-close" />
        </button>
        <Confetti
          colors={["#003893", "#ffcf04", "#ed0a26"]}
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={isMobile ? 100 : 330} // Mayor número de piezas para un efecto más explosivo
          gravity={0.2} // Gravedad para simular caída rápida
          wind={0.1} // Efecto de viento para dispersión horizontal
          initialVelocityX={{ min: -20, max: 20 }} // Velocidades iniciales en X para dispersión horizontal
          initialVelocityY={{ min: -20, max: 20 }} // Velocidades iniciales en Y para dispersión vertical
        />

        <img
          className="img-fluid"
          src="https://53fde1b606cff91e7a3b23ff9c242152.grupoalianzacolombia.com/uploads/gif.gif"
          alt="medios de pago grupo alianza colombia"
        />
      </CustomModal>
    </>
  );
};

export default MainPopUp;
