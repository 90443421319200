import React from "react";

const TermsAndConditions = () => {
  return (
    <section className="ContentEthicalLine">
      <h2 className="title-policy text-center mb-5">
        Sección términos y condiciones promocionales
      </h2>

      <article>
        <p>
          Tarifa válida para dos personas únicamente para uso en el
          <strong> Hotel Ms Cuidad Jardín</strong>, en el destino de
          <strong> Santiago de Cali, Colombia. </strong>Ciclo de viaje: cinco
          (5) noches y seis (6) días. Incluye: alimentación: (desayuno tipo
          buffet); alojamiento de cinco (5) noches y seis (6) días. Tarifa
          sujeta a disponibilidad hotelera, disponibilidad habitacional 50
          unidades.
        </p>
        <p>
          Tarifa válida para compras del{" "}
          <strong>06 de diciembre del 2024</strong> al{" "}
          <strong>30 de diciembre del 2024.</strong> Válida para ciclo de viaje
          comprendido entre el 06 de diciembre de 2024 al 31 de agosto de 2025
          teniendo como fecha límite de alojamiento la noche del 30 al 31 de
          agosto de 2025.
        </p>
        <p>
          Tarifa para uso en acomodación doble, para acomodación múltiple aplica
          suplemento de tarifa/cargo adicional o diferencia de tarifa, para
          acomodación sencilla o de única persona aplica suplemento de
          tarifa/cargo adicional o diferencia de tarifa por pérdida de
          acomodación. Aplica para viaje en temporada baja, No aplica para uso
          en puentes festivos (entiéndase puente festivo cuando el viernes o
          lunes dentro del ciclo de viaje es festivo o feriado), semana santa,
          receso escolar, fin de año. No incluye: Tiquetes de transporte
          terrestre ni aéreos, Traslados terrestres, alimentación no
          relacionada, Impuestos en destino que correspondan, asistencia médica,
          cargos no especificados en el programa y cualquier otro servicio no
          relacionado en los presentes términos y condiciones. Tarifa exclusiva
          para afiliados a Grupo Alianza Colombia S.A.S. como beneficio
          adquirido a través de su membresía vacacional. Pago directo a Grupo
          Alianza Colombia S.A.S. a través de la plataforma Alianza Priority.
        </p>
      </article>
    </section>
  );
};

export default TermsAndConditions;
