import React, { useEffect, useState } from "react";
import Loaging from "common/Loaging";
import CreateBanner from "./CreateBanner";
import CustomModal from "common/CustomModal";
import { useModalGac } from "hooks/useModalGac";
import { getBannerGac } from "services/banners";
import { deleteBanner } from "services/banners";
import toast, { Toaster } from "react-hot-toast";
import { ReactComponent as IconDelete } from "assets/icons/components/admin/delete.svg";
import { ReactComponent as IconEdit } from "assets/icons/components/admin/edit-button.svg";
import { ReactComponent as CloseIcon } from "assets/icons/components/search/close.svg";
import "styles/dashboardAdmin/tableBanners.css";

const TableBanners = () => {
  const [itemsBanners, setItemsBanners] = useState(null);
  const [isOpenModal, openModal, closeModal] = useModalGac(false);
  const [isOpenModalEdit, openModalEdit, closeModalEdit] = useModalGac(false);
  const [bannerSelect, setBannerSelect] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const listBanners = async () => {
    const response = await getBannerGac();
    setItemsBanners(response.data);
  };
  useEffect(() => {
    listBanners();
  }, []);

  const handleOptions = async (opt = "", item) => {
    setBannerSelect(item);

    switch (opt) {
      case "Edit":
        openModalEdit();

        break;
      case "Delete":
        openModal();
        break;

      default:
        break;
    }
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    try {
      await deleteBanner(id);
      toast.success("El banner se elimino correctamente");
      listBanners();
      closeModal();
    } catch (error) {
      console.error("No se pudo eliminar el banner", error);
      toast.error(`No se pudo eliminar el banner ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Toaster />
      {!itemsBanners && <Loaging />}
      <table className="custom-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Imagen</th>
            <th>Link</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          {itemsBanners?.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <img
                  src={item.img_mobile}
                  alt={`Banner ${index + 1}`}
                  className="img-table"
                  loading="lazy"
                />
              </td>
              <td>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  {item.link}
                </a>
              </td>
              <td>
                <div>
                  <button
                    className="btn-options-table edit"
                    onClick={() => handleOptions("Edit", item)}
                  >
                    <IconEdit />
                  </button>

                  <button
                    className="btn-options-table"
                    onClick={() => handleOptions("Delete", item.id)}
                  >
                    <IconDelete />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <CustomModal
        className={`modalGac ${isOpenModal && "is-open"}`}
        isOpen={isOpenModal}
        closeModal={closeModal}
      >
        <article className="card-delete">
          <CloseIcon className="icon-modal-close" onClick={closeModal} />
          <h5 className="">Eliminar Banner</h5>
          <p>¿Estás seguro de que deseas eliminar este banner?</p>
          <button
            className="btn-action"
            onClick={() => handleDelete(bannerSelect)}
          >
            {isLoading ? (
              <div
                className="spinner-border spinner-border-sm"
                role="status"
              ></div>
            ) : (
              "Si, eliminar"
            )}
          </button>
          <button className="btn-action cancel" onClick={closeModal}>
            No, Cancelar
          </button>
        </article>
      </CustomModal>
      {isOpenModalEdit && (
        <CustomModal
          className={`modalGac ${isOpenModalEdit && "is-open"}`}
          isOpen={isOpenModalEdit}
          closeModal={closeModalEdit}
        >
          <article className="card-delete">
            <CloseIcon className="icon-modal-close" onClick={closeModalEdit} />
            <CreateBanner
              modeEdit={true}
              bannerSelect={bannerSelect}
              listBanners={listBanners}
              closeModalEdit={closeModalEdit}
            />
          </article>
        </CustomModal>
      )}
    </>
  );
};

export default TableBanners;
