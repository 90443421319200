import React, { useState } from "react";
import { Payment } from "components";
import TypePayment from "components/pay/TypePayment";
import ValidateUser from "components/pay/ValidateUser";
import Selectgateway from "components/pay/Selectgateway";
import RenderBtnPayment from "components/pay/RenderBtnPayment";

import { ReactComponent as IconWhatsapp } from "assets/icons/whatsapp-icon-logo.svg";

const Payments = () => {
  const [user, setUser] = useState(null);
  const [mora, setMora] = useState(null);
  const [formConfig, setFormConfig] = useState({
    step: 1,
  });

  const showView = () => {
    switch (formConfig.step) {
      case 1:
        return (
          <ValidateUser
            setFormConfig={setFormConfig}
            setUser={setUser}
            setMora={setMora}
          />
        );
      case 2:
        return (
          <TypePayment
            setFormConfig={setFormConfig}
            user={user}
            setUser={setUser}
          />
        );
      case 3:
        return (
          <Payment
            user={user}
            setUser={setUser}
            mora={mora}
            setFormConfig={setFormConfig}
          />
        );
      case 4:
        return (
          <Selectgateway
            user={user}
            setUser={setUser}
            setFormConfig={setFormConfig}
          />
        );
      case 5:
        return <RenderBtnPayment user={user} setFormConfig={setFormConfig} />;

      default:
        break;
    }
  };

  return (
    <section className="formContainer">
      <div className="separator mb-4">
        <hr className="line" />
        <p>Plataforma de pagos</p>
        <hr className="line" />
      </div>
      <div className="centrar m-4">
        <img
          loading="lazy"
          src="/images/logo/pse-logo.png"
          alt="mercado pago"
          width="126"
          height="50"
        />
        <img
          loading="lazy"
          src="/images/logo/payu-logo.svg"
          alt="mercado pago"
          width="180"
          height="50"
        />
        <img
          loading="lazy"
          src="/images/logo/logo-mercado-pago.svg"
          alt="mercado pago"
          width="180"
          height="50"
        />
      </div>

      <a
        href="https://api.whatsapp.com/send?phone=573162971671&text=Buen%20d%C3%ADa%20%20%0ARequiero%20ayuda%20para%20realizar%20mis%C2%A0pagos"
        target="_blank"
        rel="noopener noreferrer"
        className="btn-help-whatsapp"
      >
        ¿Necesitas ayuda?
        <IconWhatsapp />
      </a>

      <img
        src="images/button_obsequio.png"
        alt="haz tu pago total de la membresía y recibe un obsequio especial"
        className="mb-4"
      />

      {showView()}
    </section>
  );
};

export default Payments;
