import axios from "axios";
import { withRetry } from "utils/apiRetry";
import { LogPaymentError, LogPaymentEvent } from "utils/logger";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL_PRIORITY,
  timeout: 5000,
});

const mercadoPago = async (paymentData) => {
  return withRetry(
    async () => {
      try {
        LogPaymentEvent("MERCADOPAGO", "request", paymentData);
        const response = await api.post("/mercadopago/pagos-web", paymentData);
        LogPaymentEvent("MERCADOPAGO", "success", response.data);
        return response;
      } catch (error) {
        LogPaymentError("MERCADOPAGO", error, paymentData);
        throw error;
      }
    },
    {
      maxRetries: 3,
      shouldRetry: (error) => error.response?.status >= 500,
    }
  );
};

const paymentsWithPayu = async (paymentData) => {
  return withRetry(
    async () => {
      try {
        LogPaymentEvent("PAYU", "request", paymentData);
        const response = await api.post("/payu/pagos-web", paymentData);
        LogPaymentEvent("PAYU", "success", response.data);
        return response;
      } catch (error) {
        LogPaymentError("PAYU", error, paymentData);
        throw error;
      }
    },
    {
      maxRetries: 2,
      shouldRetry: (error) => error.response?.status >= 500,
    }
  );
};

const getUserInfo = async (cedula) => {
  const { data } = await api.post("/users/user-payment", cedula);
  return data;
};

const getValueMora = async (codigo) => {
  const { data } = await api.post("/users/payments-web", {
    codigo: codigo,
  });
  return data;
};

export { mercadoPago, getUserInfo, getValueMora, paymentsWithPayu };
