const { apiConfig } = require("./apiConfig");

const saveClickPromo = async (payload) => {
  return await apiConfig.post("/marketing/create", payload);
};
const getAnalytics = async () => {
  return await apiConfig.get("/marketing");
};

export { saveClickPromo, getAnalytics };
