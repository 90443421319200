import React from "react";
import PropTypes from "prop-types";

const PaymentButton = ({ onClick, loading, icon, children, className }) => (
  <button
    className={`btn-payment ${className}`}
    onClick={onClick}
    disabled={loading}
    data-testid="payment-button"
  >
    {loading ? (
      <>
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
          data-testid="loading-spinner"
        />
        Cargando...
      </>
    ) : (
      <>
        {icon && <img src={icon} alt="payment icon" width={30} />}
        {children}
      </>
    )}
  </button>
);

PaymentButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  icon: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

PaymentButton.defaultProps = {
  loading: false,
  className: "",
};

export default PaymentButton;
