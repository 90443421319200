import React from "react";
import OutlinedText from "common/OutlinedText";
import Slider from "components/video-slider/Slider";
import "styles/hotels/grid-hotels.css";

const GridCustom = ({ title = "", images = [] }) => {
  const arrayImages = images.habitaciones.concat(images.slider);

  const isMobile = window.innerWidth < 768;
  const valueItems = isMobile ? 3 : 6;

  // Calcula el número total de slides
  const totalSlides = Math.ceil(arrayImages.length / valueItems);
  //   console.log("totalSlides", totalSlides);

  // Divide los hoteles en grupos de 6
  const slides = Array.from({ length: totalSlides }, (_, slideIndex) => {
    const start = slideIndex * valueItems;
    const end = start + valueItems;
    return arrayImages.slice(start, end);
  });

  return (
    <div className="container-grid-hotels">
      <OutlinedText
        position={true}
        positionTop1="16%"
        left_text1="8%"
        text1={title}
        text2={title}
        color="var(--fondo-defecto)"
        positionTop2="0"
        left_text2="5%"
      />

      <Slider
        showAlternButtons={true}
        showIndicators={false}
        showButtons={false}
        totalSlidesSend={totalSlides} // Envía el total de slides al Slider
      >
        {slides.map((hotelesGrupo, slideIndex) => (
          <section className="layout-grid-hotel" key={slideIndex}>
            {hotelesGrupo.map((hotel, index) => (
              <article
                className={`item-hotel ${
                  index === 2 ? "two-row" : index === 5 && "two-colum"
                }`}
                key={index}
              >
                <img
                  loading="lazy"
                  src={hotel.img}
                  alt={hotel.img}
                  className="img-item-hotel"
                />
                <div className="info-overlay">
                  <div>
                    <h1>{images.Hotel}</h1>
                    <span>{images.images}</span>
                  </div>
                  <button className="show-more-link" to="">
                    ver más
                  </button>
                  <div className="container-flag">
                    <img src={images.flag} alt="flag" className="flag" />
                  </div>
                </div>
              </article>
            ))}
          </section>
        ))}
      </Slider>
    </div>
  );
};

export default GridCustom;
