import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import useSendEventGA from "hooks/useSendEventGA";
import "styles/navbar.css";

const Navbar = ({ isScrolled }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const SendEvent = (e) => {
    e.preventDefault();
    window.open("https://vip.grupoalianzacolombia.com", "_blank");
    useSendEventGA("Priority", "Click en boton mi cuenta (Navbar)");
  };

  return (
    <nav className={`line-BT header ${isScrolled && "sticky1 scrolled"}`}>
      <NavLink to="/">
        <img
          loading="lazy"
          src="/images/logo/logo.webp"
          alt="Grupo Alianza Colombia Viajar"
          width={200}
          className={`${isScrolled ? "logo1" : "not-show"}`}
        />
      </NavLink>
      <button className="menu-toggle" onClick={toggleMenu}>
        &#9776;
      </button>
      <ul className={menuOpen ? "nav-open" : ""}>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              `${
                isActive && isScrolled
                  ? "active-menu-scrolled"
                  : isActive && "active-menu"
              }`
            }
          >
            Inicio
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              `${
                isActive && isScrolled
                  ? "active-menu-scrolled"
                  : isActive && "active-menu"
              }`
            }
          >
            Nosotros
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/blog"
            className={({ isActive }) =>
              `${
                isActive && isScrolled
                  ? "active-menu-scrolled"
                  : isActive && "active-menu"
              }`
            }
          >
            Blog
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contacto"
            className={({ isActive }) =>
              `${
                isActive && isScrolled
                  ? "active-menu-scrolled"
                  : isActive && "active-menu"
              }`
            }
          >
            Contáctanos
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/questions"
            className={({ isActive }) =>
              `${
                isActive && isScrolled
                  ? "active-menu-scrolled"
                  : isActive && "active-menu"
              }`
            }
          >
            Preguntas frecuentes
          </NavLink>
        </li>
        <NavLink to={"/pagos"} className="button1">
          <span className="button1__icon-wrapper">
            <svg
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="button1__icon-svg"
              width="10"
            >
              <path
                d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                fill="currentColor"
              ></path>
            </svg>

            <svg
              viewBox="0 0 14 15"
              fill="none"
              width="10"
              xmlns="http://www.w3.org/2000/svg"
              className="button1__icon-svg button1__icon-svg--copy"
            >
              <path
                d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
          Pagos
        </NavLink>
        <li>
          <button className="btn-primary" onClick={SendEvent}>
            Afiliado
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
