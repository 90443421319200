export const PAYMENT_CONFIG = {
  MERCADO_PAGO: {
    PUBLIC_KEY: process.env.REACT_APP_API_KEY_MERCADOPAGO,
    LOCALE: "es-CO",
    CONTAINER_CLASS: "cho-container",
    ERROR_MESSAGES: {
      INIT_ERROR: "Error al inicializar MercadoPago",
      PREFERENCE_ERROR: "Error al crear la preferencia de pago",
      PROCESS_ERROR: "Error al procesar el pago",
      CONTAINER_ERROR: "Error al encontrar el contenedor de pago",
    },
  },
  PAYU: {
    ERROR_MESSAGES: {
      PROCESS_ERROR: "Error al procesar el pago con PayU",
    },
  },
};
