import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ReactComponent as ArrowIcon } from "assets/icons/left-arrow.svg";
import { usePaymentGateway } from "hooks/usePaymentGateway";
import PaymentButton from "./PaymentButton";
import { LogPaymentEvent } from "utils/logger";
import "styles/pay/payments.css";

const Selectgateway = ({ setFormConfig, user, setUser }) => {
  const {
    loading,
    error,
    preferenceId,
    handlePayu,
    handleMercadoPago,
    renderMercadoPagoButton,
  } = usePaymentGateway(user, setUser, setFormConfig);

  useEffect(() => {
    if (preferenceId) {
      renderMercadoPagoButton();
      LogPaymentEvent("MERCADOPAGO", "button_rendered", { preferenceId });
    }
  }, [preferenceId, renderMercadoPagoButton]);

  return (
    <div className="payment-gateway-container" data-testid="payment-gateway">
      <div className="cho-container" data-testid="mercadopago-container" />

      <button
        className="btn-back-step"
        onClick={() => {
          LogPaymentEvent("NAVIGATION", "back_step");
          setFormConfig({ step: 3 });
        }}
        data-testid="back-button"
      >
        <ArrowIcon />
        Regresar
      </button>

      {error && (
        <div className="error-message" role="alert" data-testid="error-message">
          {error}
        </div>
      )}

      <section className="container-payments">
        <PaymentButton
          onClick={handlePayu}
          loading={loading.payu}
          icon="https://www.svgrepo.com/show/408253/world-map.svg"
          className="international"
          data-testid="payu-button"
        >
          Pagar desde el exterior
        </PaymentButton>

        <PaymentButton
          onClick={handleMercadoPago}
          loading={loading.mercadoPago}
          icon="https://www.svgrepo.com/show/405455/flag-for-flag-colombia.svg"
          className="national"
          data-testid="mercadopago-button"
        >
          Pagar desde Colombia
        </PaymentButton>
      </section>
    </div>
  );
};

Selectgateway.propTypes = {
  setFormConfig: PropTypes.func.isRequired,
  user: PropTypes.shape({
    contrato: PropTypes.string.isRequired,
    titular: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    monto: PropTypes.number.isRequired,
    codigo: PropTypes.string.isRequired,
    tipo: PropTypes.string.isRequired,
    url_payU: PropTypes.string,
  }).isRequired,
  setUser: PropTypes.func.isRequired,
};

export default Selectgateway;
