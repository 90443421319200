export const LogPaymentEvent = (gateway, eventType, data = {}) => {
  const event = {
    gateway,
    type: eventType,
    data,
    timestamp: new Date().toISOString(),
    environment: process.env.NODE_ENV,
  };

  // Enviar a tu sistema de analytics (ej: Sentry, LogRocket, etc)
  console.log(`[${gateway} Payment ${eventType}]:`, event);

  // Aquí puedes agregar la integración con tu sistema de analytics
  if (window.analytics) {
    window.analytics.track(`payment_${eventType}`, event);
  }
};

export const LogPaymentError = (gateway, error, context = {}) => {
  const errorEvent = {
    gateway,
    error: {
      message: error.message,
      stack: error.stack,
      code: error.code,
    },
    context,
    timestamp: new Date().toISOString(),
    environment: process.env.NODE_ENV,
  };

  console.error(`[${gateway} Payment Error]:`, errorEvent);

  // Aquí puedes agregar la integración con tu sistema de error tracking
  if (window.Sentry) {
    window.Sentry.captureException(error, {
      extra: errorEvent,
    });
  }
};
