import { LogPaymentEvent } from "./logger";

export const withRetry = async (operation, options = {}) => {
  const {
    maxRetries = 3,
    baseDelay = 1000,
    shouldRetry = (error) => true,
  } = options;

  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      return await operation();
    } catch (error) {
      if (attempt === maxRetries || !shouldRetry(error)) {
        throw error;
      }

      const delay = baseDelay * Math.pow(2, attempt - 1);
      await new Promise((resolve) => setTimeout(resolve, delay));

      LogPaymentEvent("RETRY", "attempt", {
        attempt,
        maxRetries,
        error: error.message,
      });
    }
  }
};
