import React, { useEffect, useState } from "react";
import { getAnalytics } from "services/analitycs-black-friday";

const ViewAnalitics = () => {
  const [TableData, setTableData] = useState(null);

  useEffect(() => {
    const getServiceAnalitycs = async () => {
      try {
        const { data } = await getAnalytics();

        setTableData(data);
      } catch (error) {
        console.log(error);
      }
    };

    getServiceAnalitycs();
  }, []);

  if (!TableData) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="container-admin">
      <h2 className="title-policy text-center mb-5">
        Estadísticas de Promociones
      </h2>
      {TableData !== null ? (
        <table className="custom-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Promoción</th>
              <th>Clicks</th>
              <th>Creado el</th>
              <th>Actualizado el</th>
            </tr>
          </thead>
          <tbody>
            {TableData.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.promocion}</td>
                <td>{item.clicks}</td>
                <td>{new Date(item.createdAt).toLocaleString()}</td>
                <td>{new Date(item.updatedAt).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Obteniendo información</p>
      )}
    </div>
  );
};

export default ViewAnalitics;
