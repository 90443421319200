import React, { useEffect } from "react";
import "styles/ScrollAnimations.css";

const useScrollAnimations = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const target = entry.target;

          if (entry.isIntersecting) {
            // Agregar clase de animación
            target.classList.add("animate");
          } else {
            // Remover la clase de animación cuando el elemento sale de la vista
            target.classList.remove("animate");
          }
        });
      },
      {
        threshold: 0.1, // Puedes ajustar este valor
      }
    );

    // Seleccionar todos los elementos que quieres animar
    const animatedElements = document.querySelectorAll(".scroll-animate");
    animatedElements.forEach((el) => observer.observe(el));

    return () => {
      animatedElements.forEach((el) => observer.unobserve(el));
    };
  }, []);
};

// Componente para secciones animadas
const AnimatedSection = ({ children, className = "" }) => {
  return (
    <section className={`scroll-animate ${className}`}>{children}</section>
  );
};

// Componente principal que aplica las animaciones
const ScrollAnimations = ({ children }) => {
  useScrollAnimations();
  return <>{children}</>;
};

export { ScrollAnimations, AnimatedSection };
