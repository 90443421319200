import React, { useEffect, useState } from "react";
import Slider from "./video-slider/Slider";
import Slider3D from "./video-slider/Slider3D";
import GridHotels from "./Hotels/GridHotels";
import Testimonials from "common/Testimonials";
import ResponsiveImage from "common/ResponsiveImage";
import { getBannerGac } from "services/banners";
// import MembershipCard from "./membership/MembershipCard";
import "styles/Home.css";
import { saveClickPromo } from "services/analitycs-black-friday";
import { AnimatedSection, ScrollAnimations } from "hooks/useScrollAnimations";

const Home = () => {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const getBanners = async () => {
      const response = await getBannerGac();
      setBanners(response.data);
    };

    getBanners();
  }, []);

  const handleMetrics = async (promocion = "", link = "") => {
    try {
      window.open(link.link, "_blank");
      await saveClickPromo({
        promocion,
        tipo: promocion,
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <ScrollAnimations></ScrollAnimations>
      <section className="add-margin">
        <Slider autoSlideInterval={20} showIndicators={true} showButtons={true}>
          {banners.map(
            ({ id, img_mobile, img_tablet, img_pc, img_large, link }) => (
              <button
                onClick={() =>
                  handleMetrics(
                    `Click en el banner ${id} con el link ${link}`,
                    { link }
                  )
                }
                className="slide-content"
                key={id}
              >
                <ResponsiveImage
                  mobil={img_mobile}
                  tablet={img_tablet}
                  pc={img_pc}
                  large={img_large}
                />
              </button>
            )
          )}
        </Slider>
      </section>
      <AnimatedSection>
        <Slider3D />
      </AnimatedSection>
      {/* <MembershipCard /> */}
      <AnimatedSection className="fade-in">
        <GridHotels />
      </AnimatedSection>

      <AnimatedSection className="slide-left">
        <Testimonials />
      </AnimatedSection>
    </>
  );
};

export default Home;
