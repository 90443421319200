import React, { useState, useEffect, useRef } from "react";
import "./CountDown.css";
import { saveClickPromo } from "services/analitycs-black-friday";

// Componente Flipper que maneja el flip
const Flipper = ({ currentTime, nextTime }) => {
  const [isFlipping, setIsFlipping] = useState(false);
  const frontRef = useRef(null);
  const backRef = useRef(null);
  const flipContainerRef = useRef(null);
  const duration = 600; // Duración de la animación en milisegundos

  useEffect(() => {
    if (currentTime !== nextTime) {
      // Inicia la animación si los números cambian
      setIsFlipping(true);
      frontRef.current.dataset.number = currentTime;
      backRef.current.dataset.number = nextTime;

      // Añadir la clase 'running' para activar la animación
      flipContainerRef.current.classList.add("running");

      // Establecer un temporizador para detener la animación después de 'duration'
      const timer = setTimeout(() => {
        // Remover la clase 'running' una vez termine la animación
        flipContainerRef.current.classList.remove("running");
        setIsFlipping(false); // Finaliza el estado de la animación
        frontRef.current.dataset.number = nextTime; // Actualizar la cara del frente con el número siguiente
      }, duration);

      // Limpiar el temporizador en caso de que el efecto se limpie antes de finalizar
      return () => clearTimeout(timer);
    }
  }, [currentTime, nextTime]); // Dependencia de currentTime y nextTime

  return (
    <div
      ref={flipContainerRef}
      className={`flip ${isFlipping ? "running" : ""}`} // Condicionalmente agregar 'running' solo cuando isFlipping es true
    >
      <div
        ref={frontRef}
        className="digital front"
        data-number={currentTime}
      ></div>
      {/* <div ref={backRef} className="digital back" data-number={nextTime}></div> */}
    </div>
  );
};

// Componente principal de la cuenta regresiva
const CountDown = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState(null);
  const [isMinimize, setIsMinimize] = useState(false);

  useEffect(() => {
    const target = new Date(targetDate);
    const now = new Date();

    // Si la fecha de destino ya ha pasado, no renderizar el componente
    if (target <= now) {
      setTimeLeft(null); // Esto hará que no se renderice nada
      return;
    }

    const updateTimeLeft = () => {
      const currentTime = new Date();
      const diff = target - currentTime;

      if (diff <= 0) {
        setTimeLeft(null); // Se acaba el tiempo, dejar de mostrar la cuenta regresiva
      } else {
        const days = Math.floor(diff / (1000 * 60 * 60 * 24)); // Días restantes
        const hours = Math.floor(
          (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ); // Horas restantes
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)); // Minutos restantes
        const seconds = Math.floor((diff % (1000 * 60)) / 1000); // Segundos restantes

        // Llenar con ceros los valores de las unidades de tiempo (dd:hh:mm:ss)
        setTimeLeft({
          days: String(days).padStart(2, "0"),
          hours: String(hours).padStart(2, "0"),
          minutes: String(minutes).padStart(2, "0"),
          seconds: String(seconds).padStart(2, "0"),
        });
      }
    };

    // Actualizar la cuenta regresiva cada segundo
    const interval = setInterval(updateTimeLeft, 1000);
    updateTimeLeft(); // Llamar inmediatamente para mostrar el primer valor

    // Limpiar intervalo cuando el componente se desmonte
    return () => clearInterval(interval);
  }, [targetDate]);

  // Si no hay tiempo restante (el tiempo ya pasó o el componente no debe renderizarse)
  if (!timeLeft) return null;

  const handleClickPromoBlackFriday = async (
    type = "",
    link = "",
    tipo = "Back Friday"
  ) => {
    try {
      window.open(link, "_blank");
      switch (type) {
        case "Ver Promociones":
          await saveClickPromo({
            promocion: "Click en el boton ver promociones",
            tipo,
          });
          break;
        case "Reservar":
          await saveClickPromo({
            promocion: "Click en el boton reservar",
            tipo,
          });
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {isMinimize ? (
        <div
          className="minifi-black-friday-container"
          onClick={() => setIsMinimize(!isMinimize)}
        >
          <div className="minifi-black-friday">Black Friday</div>
        </div>
      ) : (
        <section className="container-clock">
          <button
            className="close-button-clock"
            onClick={() => setIsMinimize(!isMinimize)}
          >
            X
          </button>
          <h5 className="text-head-clock">
            Disfruta nuestros precios Black Friday!
          </h5>

          <article className="container-buttons-clock">
            <button
              onClick={() =>
                handleClickPromoBlackFriday(
                  "Ver Promociones",
                  "https://whatsapp.com/channel/0029Va1Rycc0bIdpTK5fUJ03"
                )
              }
              className="btn-action-clock promos"
            >
              Ver Promociones
            </button>
            <button
              onClick={() =>
                handleClickPromoBlackFriday(
                  "Reservar",
                  "https://vip.grupoalianzacolombia.com"
                )
              }
              className="btn-action-clock promos"
            >
              Reservar
            </button>
          </article>
          <div className="clock">
            {/* Mostrar los días en el formato "dd" */}
            <div className="flip-container">
              <Flipper
                currentTime={timeLeft.days[0]}
                nextTime={timeLeft.days[0]}
              />
              <Flipper
                currentTime={timeLeft.days[1]}
                nextTime={timeLeft.days[1]}
              />
              <div className="label-clock">Días</div>
            </div>

            <div className="divider">:</div>

            {/* Mostrar las horas en el formato "hh" */}
            <div className="flip-container">
              <Flipper
                currentTime={timeLeft.hours[0]}
                nextTime={timeLeft.hours[0]}
              />
              <Flipper
                currentTime={timeLeft.hours[1]}
                nextTime={timeLeft.hours[1]}
              />
              <div className="label-clock">Horas</div>
            </div>

            <div className="divider">:</div>

            {/* Mostrar los minutos en el formato "mm" */}
            <div className="flip-container">
              <Flipper
                currentTime={timeLeft.minutes[0]}
                nextTime={timeLeft.minutes[0]}
              />
              <Flipper
                currentTime={timeLeft.minutes[1]}
                nextTime={timeLeft.minutes[1]}
              />
              <div className="label-clock">Minutos</div>
            </div>

            <div className="divider">:</div>

            {/* Mostrar los segundos en el formato "ss" */}
            <div className="flip-container">
              <Flipper
                currentTime={timeLeft.seconds[0]}
                nextTime={timeLeft.seconds[0]}
              />
              <Flipper
                currentTime={timeLeft.seconds[1]}
                nextTime={timeLeft.seconds[1]}
              />
              <div className="label-clock">Segundos</div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default CountDown;
