import { useState, useCallback } from "react";
import { useMercadopago } from "react-sdk-mercadopago/lib";
import { PAYMENT_CONFIG } from "../constants/payments";
import { mercadoPago, paymentsWithPayu } from "../services/Payments";

export const usePaymentGateway = (user, setUser, setFormConfig) => {
  const mercadopago = useMercadopago.v2(
    PAYMENT_CONFIG.MERCADO_PAGO.PUBLIC_KEY,
    { locale: PAYMENT_CONFIG.MERCADO_PAGO.LOCALE }
  );

  const [preferenceId, setPreferenceId] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState({
    payu: false,
    mercadoPago: false,
  });

  const handlePayu = useCallback(async () => {
    setLoading((prev) => ({ ...prev, payu: true }));
    setError(null);

    try {
      const response = await paymentsWithPayu({
        contrato: user?.contrato,
        nombre: user?.titular,
        correo: user?.email,
        monto: user?.monto,
      });

      setUser((prev) => ({ ...prev, url_payU: response.data }));
      window.open(response.data, "_blank");
      setFormConfig({ step: 5 });
    } catch (error) {
      console.error("PayU Error:", error);
      setError(PAYMENT_CONFIG.PAYU.ERROR_MESSAGES.PROCESS_ERROR);
    } finally {
      setLoading((prev) => ({ ...prev, payu: false }));
    }
  }, [user, setUser, setFormConfig]);

  const handleMercadoPago = useCallback(async () => {
    setLoading((prev) => ({ ...prev, mercadoPago: true }));
    setError(null);

    try {
      const response = await mercadoPago({
        codigo: user?.codigo,
        contrato: user?.contrato,
        tipo: user?.tipo,
        valor: user?.monto,
      });
      setPreferenceId(response.data);
    } catch (error) {
      console.error("MercadoPago Error:", error);
      setError(PAYMENT_CONFIG.MERCADO_PAGO.ERROR_MESSAGES.PREFERENCE_ERROR);
    } finally {
      setLoading((prev) => ({ ...prev, mercadoPago: false }));
    }
  }, [user]);

  const renderMercadoPagoButton = useCallback(async () => {
    if (!mercadopago || !preferenceId) return;

    const container = document.querySelector(
      `.${PAYMENT_CONFIG.MERCADO_PAGO.CONTAINER_CLASS}`
    );
    if (!container) {
      console.error(PAYMENT_CONFIG.MERCADO_PAGO.ERROR_MESSAGES.CONTAINER_ERROR);
      return;
    }

    try {
      const response = await mercadopago.checkout({
        preference: {
          id: preferenceId,
        },
        render: {
          container: `.${PAYMENT_CONFIG.MERCADO_PAGO.CONTAINER_CLASS}`,
          label: "Pagar con MercadoPago",
        },
        autoOpen: true,
      });

      if (response.status === "failure") {
        throw new Error(
          PAYMENT_CONFIG.MERCADO_PAGO.ERROR_MESSAGES.PROCESS_ERROR
        );
      }
    } catch (error) {
      console.error("MercadoPago Render Error:", error);
      setError(PAYMENT_CONFIG.MERCADO_PAGO.ERROR_MESSAGES.PROCESS_ERROR);
    }
  }, [mercadopago, preferenceId]);

  return {
    loading,
    error,
    preferenceId,
    handlePayu,
    handleMercadoPago,
    renderMercadoPagoButton,
  };
};
